import { KeyboardBackspace } from "@material-ui/icons";
import React, { useState } from "react";

const ViewAssembly = ({ assemblyData, close }) => {
  console.log(assemblyData)
  return (
    <div>
      <div className="container1">
        <div className="modalContainer" style={{ width: "72%" }}>
          <div className="header">
            <h3> BOM Assembly Details</h3>
          </div>

          <div className="body">
            <table className="viewcomp_tbl" width="100%">
              <tr>
                <th>Assembly Name</th>
                <th>Required Services</th>
                <th>Required Time</th>
              </tr>
              {assemblyData === undefined ?
                <div style={{ marginLeft: "170%", color: "red" }}>No Data Found!</div> :
                assemblyData.map((item) => {
                  return (
                    <tr>
                      <td className="leftcell">{item.assemblyName}</td>
                      <td>{item.assemblyServices.map((item) => {
                        return (
                          <ul style={{ listStyle: "none" }}><li>{item.serviceName}</li></ul>
                        )
                      })}</td>
                      <td className="rightcell">{item.assemblyServices?.map((item) => {
                        return (
                          <ul style={{ listStyle: "none" }}><li>{`${item.timeDuration} ${item.time}`}</li></ul>
                        )
                      })}</td>
                    </tr>
                  )
                })}
            </table>
          </div>
          <div className="footer">
            <button onClick={() => close(false)}> <KeyboardBackspace /> Back</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAssembly;
