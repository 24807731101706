import React, { useState } from "react";
import Edit from "./EditVendor";
import Reactivate from "./ReactivateVendor";
import Deactivate from "./DeactivateVendor";
import Blacklist from "./BlacklistVendor";
import Delete from "./DeleteVendor";
import "../../../components/modal/modal.scss";
import {
  faEdit,
  faBan,
  faTrash,
  faDotCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppConstants from "../../../utils/app-constants";

const VendorActions = ({ updatedData, rowData, setIsOpen, rowIndex }) => {
  const [showModal, setShowModal] = useState(true);
  const [activeModal, setActiveModal] = useState({});

  const clickHandler = (e) => {
    setActiveModal({
      edit: false,
      reactivate: false,
      deactivate: false,
      blacklist: false,
      delete: false,
      [e.target.id]: true,
    });
    setShowModal(true);
  };

  return (
    <div>
      {activeModal.edit && showModal && (
        <Edit
          updatedData={updatedData}
          editData={rowData}
          closeModal={setShowModal}
          setIsOpen={setIsOpen}
        />
      )}
      {rowData.status === AppConstants.ACTIVE ||
      rowData.status === AppConstants.REACTIVATED
        ? activeModal.deactivate &&
          showModal && (
            <Deactivate
              updatedData={updatedData}
              editData={rowData}
              closeModal={setShowModal}
              setIsOpen={setIsOpen}
            />
          )
        : activeModal.reactivate &&
          showModal && (
            <Reactivate
              updatedData={updatedData}
              editData={rowData}
              closeModal={setShowModal}
              setIsOpen={setIsOpen}
            />
          )}
      {activeModal.blacklist && showModal && (
        <Blacklist
          updatedData={updatedData}
          editData={rowData}
          closeModal={setShowModal}
          setIsOpen={setIsOpen}
        />
      )}
      {activeModal.delete && showModal && (
        <Delete
          updatedData={updatedData}
          editData={rowData}
          closeModal={setShowModal}
          setIsOpen={setIsOpen}
        />
      )}

      <div
        style={
          rowIndex === 0 || rowIndex === 1
            ? { position: "relative" }
            : { position: "relative", marginTop: "-148px" }
        }
      >
        <div
          style={{ marginLeft: "-89px", width: "118px" }}
          className="action vendorAction"
        >
          {(rowData.status === AppConstants.ACTIVE ||
            rowData.status === AppConstants.REACTIVATED) && (
            <div>
              <span id="edit" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faEdit} /> Edit
              </span>
            </div>
          )}

            {(rowData.status === AppConstants.ACTIVE ||
              rowData.status === AppConstants.REACTIVATED) && (
          <div>
              <span id="deactivate" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faDotCircle} /> Deactivate
              </span>
          </div>
            )}

          {rowData.status === AppConstants.DEACTIVATED && (
            <div>
              <span id="reactivate" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faDotCircle} /> Reactivate
              </span>
            </div>
          )}

          {rowData.status != "Blacklisted" && (
            <div>
              <span id="blacklist" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faBan} /> BlackList
              </span>
            </div>
          )}

          <div>
            <span id="delete" onClick={(e) => clickHandler(e)}>
              <FontAwesomeIcon icon={faTrash} /> Delete
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorActions;
