import { KeyboardBackspace } from "@material-ui/icons";
import React, { useState } from "react";

const ViewServices = ({ serviceData, close }) => {
console.log(serviceData)
  return (
    <div>
      <div className="container1">
        <div className="modalContainer" style={{ height: "200px" }}>
          <div className="header">
            <h3> Assembly Services</h3>
          </div>

          <div className="body">
  <ul className="assmlist">
    {serviceData.map((item) => {
      return (
        <li key={item.id}>
          {item.serviceName}
        </li>
      );
    })}
  </ul>
</div>

          <div className="footer">
            <button onClick={() => close(false)}>
           <KeyboardBackspace /> Back</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewServices;
