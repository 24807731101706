import React from "react";
import { Tabs } from "react-simple-tabs-component";
import auth from "../../utils/auth";
import "../settings-tabs/tabs.scss";
// import AddBomCosting from "./add-bom-costing";
// import ManageBomCosting from "./manage-bom-costing";

const AddBomCosting = React.lazy(()=>import("./add-bom-costing"))
const ManageBomCosting = React.lazy(()=>import("./manage-bom-costing"))



const tabs = [
  {
    id:"PRM5T1",
    label: "Add BOM Costing",
    Component: AddBomCosting
  },
  {
    id:"PRM5T2",
    label: "Manage BOM Costing",
    Component: ManageBomCosting
  }
];

const getTabs = (tabs) => {
  let tabIds = auth.getUserInfo().tabID.map(tab => tab.ID);
  return tabs.filter(tab => tabIds.includes(tab.id));
};

const BomCosting = () => {
  let tabsToShow = getTabs(tabs);
  return (
    <div className="app-layout__card">
      <Tabs tabs={tabsToShow} type="pills" />
    </div>
  );
};

export default BomCosting;
